import axios from "axios";

function setHeaders(customerId, access_token) {
  // axios.defaults.headers.common[
  //   "Wqr-TimeZone"
  // ] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // axios.defaults.headers.common["Wqr-CustomerIds"] = customerId;
  // axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
  return {
    "WxCC-TimeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    "WxCC-CustomerIds": customerId,
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json"
  };
}

export default {
  getSystems(customerId, access_token) {
    const headers = setHeaders(customerId, access_token);
    return axios.get("/report/admin/systems", {
      baseURL: process.env.VUE_APP_HEROKU_URL,
      headers
    });
  },
  getAllSystems(customerId, access_token) {
    const headers = setHeaders(customerId, access_token);
    return axios.get("/report/admin/allsystems", {
      baseURL: process.env.VUE_APP_HEROKU_URL,
      headers
    });
  },
  getCustomers(customerId, access_token) {
    const headers = setHeaders(customerId, access_token);
    return axios.get("/report/admin/allcustomers", {
      baseURL: process.env.VUE_APP_HEROKU_URL,
      headers
    });
  },
  // getAllCustomers(customerId, access_token) {
  async getAllCustomers(access_token) {
    return axios.get("/api/admin/customers", {
      baseURL: process.env.VUE_APP_HEROKU_URL,
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json"
      }
    });
  },
  async saveCustomer(customer, access_token) {
    return axios.put(`/api/admin/customer/${customer._id}`, customer,{
      baseURL: process.env.VUE_APP_HEROKU_URL,
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json"
      }
    });
    // const headers = setHeaders(customerId, access_token);
    // return axios.put(`/report/admin/customer/${customer._id}`, customer, {
    //   baseURL: process.env.VUE_APP_HEROKU_URL,
    //   headers
    // });
  },
  async addNewCustomer(customer, access_token) {
    return axios.post(`/api/admin/customer`, customer,{
      baseURL: process.env.VUE_APP_HEROKU_URL,
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json"
      }
    });
  },
  async getAllIntegrations(access_token) {
    return axios.get("/api/admin/integrations", {
      baseURL: process.env.VUE_APP_HEROKU_URL,
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json"
      }
    });
  },
  getAllUsers(access_token) {
    return axios.get(
      `https://${process.env.VUE_APP_AUTH0_DOMAIN}/api/v2/users?include_totals=true&search_engine=v3&sort=email%3A1&fields=email%2Cname%2Cpicture%2Cuser_metadata%2Clast_login%2Clogins_count%2Cuser_id%2Cemail_verified&page=0&per_page=100`,
      {
        baseURL: process.env.VUE_APP_HEROKU_URL,
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json"
        }
      }
    );
  },
  updateCustomerFromUser(user, access_token) {
    return axios.patch(
      `https://${process.env.VUE_APP_AUTH0_DOMAIN}/api/v2/users/${user.user_id}`,
      {
        user_metadata: {
          customerId: user["user_metadata"]["customerId"]
        }
      },
      {
        baseURL: process.env.VUE_APP_HEROKU_URL,
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json"
        }
      }
    );
  },
  updateRolesFromUser(user, access_token) {
    return axios.patch(
      `https://${process.env.VUE_APP_AUTH0_DOMAIN}/api/v2/users/${user.user_id}`,
      {
        user_metadata: {
          roles: user["user_metadata"]["roles"]
        }
      },
      {
        baseURL: process.env.VUE_APP_HEROKU_URL,
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json"
        }
      }
    );
  },
  updateUserName(user, access_token) {
    return axios.patch(
      `https://${process.env.VUE_APP_AUTH0_DOMAIN}/api/v2/users/${user.user_id}`,
      {
        name: user["name"]
      },
      {
        baseURL: process.env.VUE_APP_HEROKU_URL,
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json"
        }
      }
    );
  },
  getSha(customerId, access_token) {
    const headers = setHeaders(customerId, access_token);
    return axios.get(`/report/admin/filesha/${customerId}`, {
      baseURL: process.env.VUE_APP_HEROKU_URL,
      headers
    });
  }
}; //end of export default
