"use strict";

import Vue from "vue";
import Router from "vue-router";

import Login from "./views/Login.vue";
import Callback from "./views/Callback.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "landing",
      component: Login,
      beforeEnter: (to, from, next) => {
        next();
      }
    },
    {
      path: "/callback",
      name: "callback",
      component: Callback
    },
    {
      path: "/users",
      name: "users",
      meta: {
        requiresAuth: true
      },
      component: () =>
        import(/* webpackChunkName: "user" */ "./views/UserAdmin.vue")
    },
    {
      path: "/customers",
      name: "customers",
      meta: {
        requiresAuth: true
      },
      component: () =>
        import(/* webpackChunkName: "customer" */ "./views/CustomerAdmin.vue")
    },
    {
      path: "/integrations",
      name: "integrations",
      meta: {
        requiresAuth: true
      },
      component: () =>
        import(/* webpackChunkName: "integration" */ "./views/IntegrationAdmin.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    var isAuthenticated = router.app.$auth.isAuthenticated();
    if (!isAuthenticated) {
      router.app.$auth
        .renewTokens()
        .then(() => {
          next();
        })
        .catch(() => {
          router.app.$auth.logout();
          next("/");
        });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
