import auth0 from "auth0-js";
import Vue from "vue";

// const webAuth = new auth0.WebAuth({
//   domain: process.env.VUE_APP_AUTH0_DOMAIN,
//   redirectUri: `${window.location.origin}/#/dashboard`,
//   clientID: process.env.VUE_APP_AUTH0_CLIENTID,
//   responseType: 'id_token',
//   scope: 'openid profile email'
// });

let webAuth = new auth0.WebAuth({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientID: process.env.VUE_APP_AUTH0_CLIENTID,
  // make sure this line is contains the port: 8080
  redirectUri: process.env.VUE_APP_AUTH0_LOGIN || "http://localhost:8080/",
  // we will use the api/v2/ to access the user information as payload
  audience: "https://" + process.env.VUE_APP_AUTH0_DOMAIN + "/api/v2/",
  responseType: "token id_token",
  scope: "openid profile" // define the scopes you want to use
});

let auth = new Vue({
  data() {
    return {
      expires: 0,
      token: '',
      accessToken: '',
      user: {}
    };
  },
  computed: {
    // token: {
    //   get: function() {
    //     return localStorage.getItem("id_token");
    //   },
    //   set: function(id_token) {
    //     localStorage.setItem("id_token", id_token);
    //   }
    // },
    // accessToken: {
    //   get: function() {
    //     return localStorage.getItem("access_token");
    //   },
    //   set: function(accessToken) {
    //     localStorage.setItem("access_token", accessToken);
    //   }
    // },
    expiresAt: {
      get: function() {
        // console.log(
        //   "computed.expiresAt.get localStorage expires_at",
        //   localStorage.getItem("expires_at")
        // );
        // this.expires = localStorage.getItem("expires_at");
        return this.expires;
        // return localStorage.getItem("expires_at");
      },
      set: function(expiresIn) {
        this.expires = expiresIn * 1000 + new Date().getTime();
        // console.log("computed.expiresAt.set", expiresIn);
        // let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());
        //   let expiresAt = expiresIn * 1000 + new Date().getTime();
        // console.log("computed.expiresAt.set expiresAt", expiresAt);
        // localStorage.setItem("expires_at", expiresAt);
        // console.log(
        //   "computed.expiresAt.set localStorage expires_at",
        //   localStorage.getItem("expires_at")
        // );
      }
    },
    // user: {
    //   get: function() {
    //     // console.log(
    //     //   "computed.user.get localStorage expires_at",
    //     //   localStorage.getItem("user")
    //     // );
    //     return JSON.parse(localStorage.getItem("user"));
    //   },
    //   set: function(user) {
    //     localStorage.setItem("user", JSON.stringify(user));
    //   }
    // }
  },
  methods: {
    login() {
      webAuth.authorize();
    },
    logout() {
      return new Promise(() => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("user");
        localStorage.removeItem("webex_api_access_token");
        localStorage.removeItem("webex_api_refresh_token");
        localStorage.removeItem("webex_api_expires_in");
        localStorage.removeItem("webex_api_refresh_token_expires_in");
        webAuth.logout({
          returnTo:
            process.env.VUE_APP_AUTH0_LOGOUT || "http://localhost:8080/", // Allowed logout URL listed in dashboard
          clientID: process.env.VUE_APP_AUTH0_CLIENTID // Your client ID
        });
        //   this.$store.commit('setIsAuthenticated', false);
      });
    },
    isAuthenticated() {
      // console.log("isAuthenticated called ", this.expiresAt);
      var notExpired = new Date().getTime() < this.expiresAt;
      // console.log("isAuthenticated called ", notExpired);
      // console.log("localStorage ", localStorage.getItem("expires_at"));
      return notExpired;
    },
    localLogin(authResult) {
      // console.log(
      //   "++++ storing handleAuthentication expiresIn",
      //   authResult.expiresIn
      // );
      // console.log(
      //   "++++ storing handleAuthentication idTokenPayload",
      //   authResult.idTokenPayload
      // );
      // this.expiresAt = 120;
      this.expiresAt = authResult.expiresIn;
      this.accessToken = authResult.accessToken;
      this.token = authResult.idToken;
      this.user = authResult.idTokenPayload;
      this.saveToStorage(authResult.accessToken, authResult.idToken, this.expiresAt, authResult.idTokenPayload);
      // console.log("++++ stored value expiresAt", this.expiresAt);
      // console.log("++++ stored value user", this.user);
    },
    handleAuthentication() {
      // console.log("++start handleAuthentication");
      return new Promise((resolve, reject) => {
        webAuth.parseHash((err, authResult) => {
          // console.log("++handleAuthentication", JSON.stringify(authResult));
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.localLogin(authResult);
            resolve(authResult.idTokenPayload["http://cloud-portal/customerId"]);
            // if (
            //   authResult.idTokenPayload["http://cloud-portal/roles"] &&
            //   authResult.idTokenPayload["http://cloud-portal/roles"].indexOf(
            //     "atea_cloud_admin"
            //   ) > -1
            // ) {
            //   // authResult.expiresIn = 10;
            //   // console.log(authResult);
            //   this.localLogin(authResult);
            //   resolve(authResult.idTokenPayload["http://cloud-portal/customerId"]);
            // } else {
            //   window.setTimeout(this.logout, 5000);
            //   reject(
            //     `Your account [${
            //       authResult.idTokenPayload.name
            //     }] does not have an administrator role`
            //   );
            // }
          } else if (err) {
            this.logout();
            reject(err);
          } else {
            reject();
          }
        });
      });
    },
    renewTokens() {
      return new Promise((resolve, reject) => {
        if (localStorage.getItem("expires_at") === null) {
          return reject("Not logged in");
        }
        // console.log('renewTokens');
        webAuth.checkSession({}, (err, authResult) => {
          if (err) {
            reject(err);
          } else {
            // reject(err);
            // authResult.expiresIn = 10;
            this.localLogin(authResult);
            resolve(authResult);
          }
        });
      });
    },
    restoreStorage(){
      if(localStorage.getItem('access_token')) this.accessToken = localStorage.getItem('access_token');
      if(localStorage.getItem('id_token')) this.token = localStorage.getItem('id_token');
      if(localStorage.getItem('expires_at')) this.expires = localStorage.getItem('expires_at');
      if(localStorage.getItem('user')) this.user = JSON.parse(localStorage.getItem('user'));
    },
    saveToStorage(accessToken, token, expires, user){
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('id_token', token);
      localStorage.setItem('expires_at', expires);
      localStorage.setItem('user', JSON.stringify(user));
    }
  },
  mounted(){
    this.restoreStorage();
  }
});

export default {
  install: function(Vue) {
    Vue.prototype.$auth = auth;
  }
};
