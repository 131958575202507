<template>
  <div class="callback"></div>
</template>

<script>
export default {
  name: "callback",
  mounted() {
    this.$auth.handleAuthentication().then(custId => {
      this.$store.commit("setIsAuthenticated", true);
      this.$store.commit("setCustomerId", custId);
      this.$store.dispatch("getCustomers");
      this.$store._vm.$bvToast.toast("Welcome back " + this.$auth.user.name, {
        title: "Cloud Portal",
        autoHideDelay: 5000,
        variant: "success",
        appendToast: true,
        toaster: "b-toaster-top-center"
      });
      this.$router.push({ name: "landing" });
    });
  }
};
</script>
