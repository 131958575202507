<template>
  <div id="app">
    <div class="d-flex" id="wrapper">
      <div class="bg-light border-right" id="sidebar-wrapper">
        <div class="sidebar-heading" style="height: 59px;">
          <img alt="Atea Logo" src="./assets/logo.png" />&nbsp;Cloud Portal
        </div>
        <div class="list-group list-group-flush">
          <a
            href="#"
            class="list-group-item list-group-item-action bg-light"
            @click.prevent="login"
            v-if="!isLoggedIn"
            ><font-awesome-icon icon="sign-in-alt" /> Login</a
          >
          <router-link
            class="list-group-item list-group-item-action bg-light"
            to="/users"
            v-if="isLoggedIn && isUserAdmin"
            ><font-awesome-icon icon="users-cog" /> Users [Atea]</router-link
          >
          <router-link
            class="list-group-item list-group-item-action bg-light"
            to="/customers"
            v-if="isLoggedIn && isUserAdmin"
            ><font-awesome-icon icon="database" /> Customers [Atea]</router-link
          >
          <router-link
            class="list-group-item list-group-item-action bg-light"
            to="/integrations"
            v-if="isLoggedIn && isUserAdmin"
            ><font-awesome-icon icon="link" /> Integrations [Atea]</router-link
          >
         <a
            href="https://dashboard.wxcc.ateasystems.com/" target="_blank"
            class="list-group-item list-group-item-action bg-light"
            v-if="isLoggedIn"
            ><font-awesome-icon icon="sign-out-alt"/>WxCC Dashboard</a>
         <a
            href="#"
            class="list-group-item list-group-item-action bg-light"
            @click.prevent="logout"
            v-if="isLoggedIn"
            ><font-awesome-icon icon="sign-out-alt"/> Logout<br /><img
              style="max-width: 20px;"
              :src="userAvatar"/>
            {{ userNickname }}<br
          /></a>
        </div>
        <div id="footer">
          Cloud Portal v22.0.01 - &copy; Copyright {{ theYear }}<br />Atea Systems
          Ltd.<br />All rights reserved.
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      theYear: new Date().getFullYear(),
      isAuthenticated: false,
      message: "",
      renewalTimer: null
    };
  },
  created() {},
  methods: {
    login() {
      this.$auth.login();
    },
    logout() {
      if (this.renewalTimer !== null) window.clearTimeout(this.renewalTimer);
      this.$auth.logout();
    }
  },
  computed: {
    isLoggedIn() {
      return this.$auth.isAuthenticated();
    },
    userNickname() {
      return this.$store.getters.isLoggedIn ? this.$auth.user.name : "";
    },
    userAvatar() {
      return this.$store.getters.isLoggedIn ? this.$auth.user.picture : "";
    },
    isUserAdmin() {
      if (
        this.$store.getters.isLoggedIn &&
        this.$auth.user["http://cloud-portal/scope"].indexOf("read:users") > -1 &&
        this.$auth.user["http://cloud-portal/roles"].indexOf("atea_cloud_admin") > -1
      )
        return true;
      return false;
    }
  },
  async mounted() {
    try {
      await this.$auth.renewTokens();
      if (this.$auth.isAuthenticated()) {
        let custId = this.$auth.user["http://cloud-portal/customerId"];
        this.$store.commit("setIsAuthenticated", true);
        this.$store.commit("setCustomerId", custId);
        // this.$store.dispatch("getCustomers");
        var expiresAt = this.$auth.expiresAt;
        var delay = expiresAt - Date.now();
        if (delay > 0) {
          this.renewalTimer = window.setTimeout(
            this.$auth.renewTokens(),
            delay
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#footer {
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  font-size: 12px;
  width: 15rem;
}
.router-link-exact-active {
  color: #a12321 !important;
  font-weight: bold;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}
#page-content {
  margin-top: 20px;
  margin-left: 20px;
}
.page-header-wrapper {
  width: 100%;
}
#page-content-wrapper .page-header-wrapper {
  padding: 0.55rem 1.25rem;
  font-size: 1.2rem;
}
#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
</style>
