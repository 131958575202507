<template>
  <div id="page-content-wrapper text-left">
    <div id="page-content" class v-if="!isAuthenticated">
      Please Login
    </div>
    <div id="page-content" class v-if="isAuthenticated">
      Hi {{ this.$auth.user.name }}, you are now Logged in
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      showSpinner: false
    };
  },
  computed: {
    isAuthenticated() {
      return this.$auth.isAuthenticated();
    }
  },
  methods: {
    login: function() {
      this.showSpinner = true;
    }
  }
};
</script>
