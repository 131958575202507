import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";
import ReportService from "@/service/ReportService";
import BootstrapVue from "bootstrap-vue";
Vue.use(Vuex);
Vue.use(BootstrapVue);
export default new Vuex.Store({
  state: {
    selectedCustomer: null,
    systems: [],
    allSystems: [],
    allCustomers: [],
    isLoggedIn: false,
    customerId: null,
    allAlerts: []
  },
  mutations: {
    setActiveCustomer(state, customer) {
      state.selectedCustomer = customer;
    },
    setIsAuthenticated(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setCustomerId(state, customerId) {
      state.customerId = customerId;
    },
    SET_SYSTEM_NAMES(state, sysnames) {
      state.systems = sysnames;
    },
    SET_ALL_SYSTEM_NAMES(state, sysnames) {
      state.allSystems = sysnames;
    },
    SAVE_SYSTEM_NAME(state, system) {
      state.systems = state.systems.map(el => {
        if (el.system === system.system) el.description = system.description;
        return el;
      });
    },
    DEL_SYSTEM_NAME(state, system) {
      state.systems = state.systems.filter(el => {
        return el.system !== system.system;
      });
      state.allSystems.push(system);
    },
    ADD_SYSTEM_NAME(state, system) {
      state.allSystems = state.allSystems.filter(el => {
        return el.system !== system.system;
      });
      state.systems.push(system);
    },
    SET_CUSTOMER_NAMES(state, customers) {
      state.allCustomers = customers;
      if (state.selectedCustomer === null)
        state.selectedCustomer = customers[0].customerId;
    },
    SAVE_ALERTS(state, alerts) {
      state.allAlerts = alerts;
    },
    SAVE_ALERT(state, alert) {
      state.allAlerts = state.allAlerts.map(el => {
        if (el.group === alert.group && el.destination === alert.destination)
          el = alert;
        return el;
      });
    },
    ADD_ALERT(state, alert) {
      state.allAlerts.push(alert);
    },
    DEL_ALERT(state, alert) {
      state.allAlerts = state.allAlerts.filter(el => {
        if (el.group === alert.group && el.destination === alert.destination)
          return false;
        else return true;
      });
    }
  },
  actions: {
    doToast(context, {message, variant}) {
      this._vm.$bvToast.toast(message, {
        title: "WQR Admin",
        autoHideDelay: 5000,
        variant: variant,
        appendToast: true,
        toaster: "b-toaster-top-center"
      });
    },
    getCustomers(context) {
      ReportService.getCustomers(
        this._vm.$auth.user["http://cloud-portal/customerId"],
        this._vm.$auth.accessToken
      ).then(result => {
        context.commit(
          "SET_CUSTOMER_NAMES",
          result.data
            .map(el => ({
              customerName: el.customerName,
              customerId: el.customerId
            }))
            .sort((a, b) => {
              if (b.customerName > a.customerName) return -1;
              if (a.customerName > b.customerName) return 1;
              return 0;
            })
        );
        ReportService.getSystems(
          context.getters.selectedCustomer,
          this._vm.$auth.accessToken
        ).then(result => {
          context.commit(
            "SET_SYSTEM_NAMES",
            result.data
              .map(el => ({ system: el.systemName, description: el.unitAlias }))
              .sort((a, b) => {
                if (b.system > a.system) return -1;
                if (a.system > b.system) return 1;
                return 0;
              })
          );
        });
        ReportService.getAllSystems(
          context.getters.selectedCustomer,
          this._vm.$auth.accessToken
        ).then(result => {
          context.commit(
            "SET_ALL_SYSTEM_NAMES",
            result.data
              .map(el => ({ system: el }))
              // .filter(el => {
              //   return !result.data.find(el2 => {
              //     return el2.systemName === el.system;
              //   });
              // })
              .sort((a, b) => {
                if (b.system > a.system) return -1;
                if (a.system > b.system) return 1;
                return 0;
              })
          );
        });
      });
    },
    saveSystem(context, sys) {
      ReportService.saveSystem(
        context.getters.selectedCustomer,
        this._vm.$auth.accessToken,
        sys
      )
        .then(() => {
          context.commit("SAVE_SYSTEM_NAME", {
            system: sys.system,
            description: sys.description
          });
          this.dispatch("doToast", {
            message: "Updated System Description",
            variant: "success"
          });
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          // console.dir(this);
          this.dispatch("doToast", {
            message: "Failed to Update System Description",
            variant: "danger"
          });
        });
    },
    delSystem(context, sys) {
      ReportService.delSystem(
        context.getters.selectedCustomer,
        this._vm.$auth.accessToken,
        sys
      )
        .then(() => {
          context.commit("DEL_SYSTEM_NAME", { system: sys.system });
          this.dispatch("doToast", {
            message: "Deleted System Description",
            variant: "success"
          });
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.dispatch("doToast", {
            message: "Failed to Delete System Description",
            variant: "danger"
          });
        });
    },
    addSystem(context, sys) {
      ReportService.addSystem(
        context.getters.selectedCustomer,
        this._vm.$auth.accessToken,
        sys
      )
        .then(() => {
          context.commit("ADD_SYSTEM_NAME", {
            system: sys.system,
            description: sys.description
          });
          this.dispatch("doToast", {
            message: "Added System Description",
            variant: "success"
          });
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.dispatch("doToast", {
            message: "Failed to Add System Description",
            variant: "danger"
          });
        });
    },
    saveAlert(context, alert) {
      ReportService.saveAlert(
        context.getters.selectedCustomer,
        this._vm.$auth.accessToken,
        alert
      )
        .then(() => {
          context.commit("SAVE_ALERT", alert);
          this.dispatch("doToast", {
            message: "Updated Alert",
            variant: "success"
          });
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.dispatch("doToast", {
            message: "Failed to Update Alert",
            variant: "danger"
          });
        });
    },
    addAlert(context, alert) {
      ReportService.addAlert(
        context.getters.selectedCustomer,
        this._vm.$auth.accessToken,
        alert
      )
        .then(res => {
          // console.log("added alert res", res);
          // console.log("added alert ", alert);
          alert._id = res.data;
          context.commit("ADD_ALERT", alert);
          this.dispatch("doToast", {
            message: "Added Alert",
            variant: "success"
          });
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.dispatch("doToast", {
            message: "Failed to Add Alert",
            variant: "danger"
          });
        });
    },
    delAlert(context, alert) {
      ReportService.delAlert(
        context.getters.selectedCustomer,
        this._vm.$auth.accessToken,
        alert
      )
        .then(() => {
          context.commit("DEL_ALERT", alert);
          this.dispatch("doToast", {
            message: "Deleted Alert",
            variant: "success"
          });
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.dispatch("doToast", {
            message: "Failed to Delete Alert",
            variant: "danger"
          });
        });
    }
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    customerId: state => state.customerId,
    selectedCustomer: state => state.selectedCustomer,
    allAlerts: state => state.allAlerts,
    selectedCustomerName: state => {
      return state.allCustomers.filter(c => {
        return c.customerId === state.selectedCustomer;
      }).map(cu => {
        return cu.customerName.toLowerCase().replace(' ', '_');
      });
    }
  }
});
