import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import auth from "@/auth/AuthService";
Vue.use(auth);

import router from "./router";

Vue.use(BootstrapVue);
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faSignInAlt,
  faSignOutAlt,
  faPenSquare,
  faExternalLinkAlt,
  faUsersCog,
  faMinusCircle,
  faPlusCircle,
  faDatabase,
  faCloud,
  faLink
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faExclamationTriangle,
  faSignInAlt,
  faSignOutAlt,
  faPenSquare,
  faExternalLinkAlt,
  faUsersCog,
  faMinusCircle,
  faPlusCircle,
  faDatabase,
  faCloud,
  faLink
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
